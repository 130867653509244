import React from "react";
import { useInView } from "react-intersection-observer";
import { CardsProps } from "../Interfaces";
import "../Styles/companies_cards.css";

const CompaniesCards: React.FC<CardsProps> = (props) => {
  const { imageUrl, text, delay } = props;
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
    <div
      id="card-companies"
      ref={ref}
      className={inView ? `animate__animated animate__zoomIn ${delay}` : ""}
    >
      <div>
        <img src={imageUrl} alt="" id="card-image-companies" />
      </div>
      <span>{text}</span>
    </div>
  );
};

export default CompaniesCards;
