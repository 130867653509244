/* eslint-disable import/no-anonymous-default-export */
import business from "./business.svg";
import footer_image from "./footer_image.svg";
import freeBankLogo from "./freeBankLogo.svg";
import like from "./like.svg";
import proziLogo from "./proziLogo.svg";
import refresh from "./refresh.svg";
import sbGoupLogo from "./sbGoupLogo.svg";
import sbLabsLogo from "./sbLabsLogo.svg";
import smile from "./smile.svg";
import star from "./star.svg";
import sun from "./sun.svg";
import teste_imagem from "./teste_imagem.svg";
import unimedLogo from "./unimedLogo.svg";
import urbaniiLogo from "./urbaniiLogo.svg";
import users from "./users.svg";

export default {
  footer_image,
  like,
  refresh,
  sbGoupLogo,
  smile,
  star,
  sun,
  teste_imagem,
  users,
  business,
  sbLabsLogo,
  freeBankLogo,
  unimedLogo,
  urbaniiLogo,
  proziLogo,
};
