import svgIcons from "../Imagens/svg";
import "../Styles/benefits.css";
import BenefitsCards from "../utils/BenefitsCards";

export default function Benefits() {
  const benefitData = [
    {
      text: "Princípios acima de tudo",
      imageUrl: svgIcons.star,
      delay: "animate__delay-1s",
    },
    {
      text: "Ser mais para o cliente",
      imageUrl: svgIcons.like,
      delay: "animate__delay-2s",
    },
    {
      text: "Evoluir e inovar para perenizar",
      imageUrl: svgIcons.sun,
      delay: "animate__delay-3s",
    },
    {
      text: "Gente que faz a diferença",
      imageUrl: svgIcons.users,
      delay: "animate__delay-4s",
    },
    {
      text: "Agir como dono",
      imageUrl: svgIcons.smile,
      delay: "animate__delay-5s",
    },
    {
      text: "Resultados sustentáveis",
      imageUrl: svgIcons.refresh,
      delay: "animate__delay-6s",
    },
  ];

  return (
    <div className="benefits-container">
      <h1 id="benefits-title">Conheça nossas vantagens</h1>
      <div className="cards-benefits">
        {benefitData.map((benefit, index) => (
          <BenefitsCards
            delay={benefit.delay}
            key={index}
            text={benefit.text}
            imageUrl={benefit.imageUrl}
          />
        ))}
      </div>
    </div>
  );
}
