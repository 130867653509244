import React from 'react';

export const LeftArrow: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M15.5 18.5464L9.5 12.5464L15.5 6.54639'
        stroke='#A8A7A7'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const RightArrow: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M9.5 18.5464L15.5 12.5464L9.5 6.54639'
        stroke='#721BE6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
