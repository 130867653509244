import About from "./Components/About";
import Benefits from "./Components/Benefits";
import Companies from "./Components/Companies";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Solutions from "./Components/Solutions";
import "./Styles/app.css";

function App() {
  return (
    <div id="general-container">
      <Header />
      <About />
      <Benefits />
      <Solutions />
      <Contact />
      <Companies />
      <Footer />
    </div>
  );
}

export default App;
