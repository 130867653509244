import React from "react";
import { useInView } from "react-intersection-observer";
import { CardsProps } from "../Interfaces";
import "../Styles/benefits_cards.css";

const BenefitsCards: React.FC<CardsProps> = (props) => {
  const { text, imageUrl, delay } = props;
  const { ref, inView } = useInView({
    triggerOnce: true,
    trackVisibility: false,
  });
  return (
    <div
      id="card-benefits"
      className={inView ? `animate__animated animate__zoomIn ${delay}` : ""}
      ref={ref}
    >
      <img id="card-image-benefits" src={imageUrl} alt={text} />
      <span id="card-benefits-text">{text}</span>
    </div>
  );
};

export default BenefitsCards;
