import svgIcons from "../Imagens/svg";
import "../Styles/footer.css";

export default function Footer() {
  return (
    <div id="footer-container">
      <img src={svgIcons.footer_image} alt="footer_image" id="footer-image" />
      <span id="footer-text">Copyright © 2016 Premye. All Rights Reserved</span>
    </div>
  );
}
