import svgIcons from "../Imagens/svg";
import "../Styles/companies.css";
import CompaniesCards from "../utils/CompaniesCards";

export default function Companies() {
  return (
    <div id="companies-container">
      <div id="companies-title">
        <h1>Nossos clientes</h1>
      </div>
      <div id="companies-cards">
        <CompaniesCards
          delay={"animate__delay-1s"}
          imageUrl={svgIcons.sbGoupLogo}
          text={"SB Group"}
        />
        <CompaniesCards
          delay={"animate__delay-2s"}
          imageUrl={svgIcons.sbLabsLogo}
          text={"SB Labs"}
        />
        <CompaniesCards
          delay={"animate__delay-3s"}
          imageUrl={svgIcons.freeBankLogo}
          text={"FreeBrank"}
        />
        <CompaniesCards
          delay={"animate__delay-4s"}
          imageUrl={svgIcons.unimedLogo}
          text={"Unimed"}
        />
        <CompaniesCards
          delay={"animate__delay-5s"}
          imageUrl={svgIcons.urbaniiLogo}
          text={"Urbani"}
        />
        <CompaniesCards
          delay={"animate__delay-6s"}
          imageUrl={svgIcons.proziLogo}
          text={"Prozi"}
        />
      </div>
    </div>
  );
}
